<template>
  <div v-if="model">
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <v-card outlined>
          <v-card-title v-if="model.id">Editing {{ model.name }}</v-card-title>
          <v-card-title v-else>Creating new model</v-card-title>
          <v-card-text>
            <m-field v-model="model.name" m="certification_authority" a="name" />
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn type="submit" color="primary">{{ $t('actions.save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import {Component, Prop, Vue} from "vue-property-decorator";
import formatter from "@/mixins/formatter";

@Component({mixins: [formatter]})
export default class StockMarket extends Vue {

  @Prop() modelId;

  model = null;

  mounted() {
    if (this.modelId) {
      this.$api.get(`certification-authority/${this.modelId}`).then(j => {
        this.model = j.data;
      });
    } else {
      this.model = {
        name: "",
      }
    }
  }

  onSubmit() {
    const call = this.modelId
        ? this.$api.patch(`certification-authority/${this.modelId}`, this.model)
        : this.$api.post(`certification-authority`, this.model)

    call.then(j => {
      this.$emit('save', j.data);
    }).catch(err => {
      if (err.response) this.$refs.form.setErrors(err.response.data);
      this.$snack.validationError(err.response.data)
    });
  }

  get rules() {
    return this.$store.state.rules;
  }

}
</script>

<style></style>
